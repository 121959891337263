import "./App.css";
import {Checkbox, FormControlLabel, Paper, ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";
import {AutoStories, Calculate, Speed} from "@mui/icons-material";
import {useEffect, useState} from "react";
import ReactMarkdown from "react-markdown";

const dictionaryMarkdown = `
# Argumentacja
- **Bogata argumentacja** to argumentacja pogłębiona, poparta trafnymi przykładami i szeroka/wieloaspektowa, stanowiąca – jako całość – wnikliwą analizę zagadnienia sformułowanego w poleceniu, zawierająca elementy refleksji/głębszego namysłu nad zagadnieniem.
- **Zadowalająca argumentacja** to argumentacja pogłębiona, poparta trafnymi przykładami.
- **Powierzchowna argumentacja** to argumentacja oparta na uogólnieniach, niewnikająca w istotę rzeczy, poprzestająca na pobieżnych obserwacjach, mało dokładna, czasami niepoparta przykładami.
# Błędy rzeczowe
Poważny błąd rzeczowy to błąd świadczący o: 
- nieznajomości wskazanej w poleceniu lektury obowiązkowej (z podstawy programowej), do której odwołuje się zdający, w zakresie innym niż w przypadku błędu kardynalnego, tj. np. błąd w przypisaniu autorstwa, w użyciu błędnego nazwiska/imienia bohatera, dotyczący 
losów bohaterów drugoplanowych bądź wątków innych niż główne 
- nieznajomości utworu literackiego lub tekstu kultury, do którego odwołuje się zdający, innego niż lektura obowiązkowa (każdy błąd merytoryczny)
- braku wiedzy dotyczącej kontekstu wybranego przez zdającego, np. błędne przywołanie pojęć lub faktów historycznych.
# Kompozycja
- Kompozycja wypowiedzi jest **spójna**, jeżeli wypowiedź zawiera wstęp, część zasadniczą i zakończenie, a te elementy składają się na logiczną i uporządkowaną całość. 
- Kompozycja wypowiedzi jest **częściowo spójna**, jeżeli w wypowiedzi: 
  - brakuje wstępu oraz/lub zakończenia ALBO 
  - połączenie między dwoma z trzech elementów wypowiedzi, tj. wstępem a częścią zasadniczą, ALBO częścią zasadniczą a zakończeniem, ALBO wstępem a zakończeniem jest nielogiczne, ALBO
  - zawarto treści nieistotne, zbędne dla omawianego zagadnienia, bez związku z omawianym 
zagadnieniem. 
- Kompozycja wypowiedzi jest **niespójna**, jeżeli w wypowiedzi: 
  - brakuje części zasadniczej ALBO
  - połączenia między trzema elementami wypowiedzi, tj. wstępem, częścią zasadniczą a zakończeniem są nielogiczne, ALBO
  - zawarto nieuzasadnione wnioski, sprzeczne stwierdzenia.
# Wypowiedzi podczas rozmowy
- Wypowiedzi **na temat** to wypowiedzi: 
  - adekwatne do zadawanych pytań 
  - poprawne merytorycznie. 
- Wypowiedzi **częściowo na temat** to wypowiedzi nieadekwatne do zadawanych pytań ALBO niepoprawne merytorycznie.
- Wypowiedzi zdającego podczas rozmowy są **właściwie uszczegółowione**, jeżeli nie ograniczają się wyłącznie do ogólnikowych stwierdzeń, a zawierają ich rozwinięcie. 
- **Zaburzenia w stopniu uszczegółowienia wypowiedzi** wynikają z operowania ogólnikami przez zdającego w czasie rozmowy, z braku precyzji w wyjaśnianiu omawianych zagadnień/kwestii/pojęć itp.
# Poprawność językowa
- Nie każde nieprecyzyjne sformułowanie jest niepoprawne językowo (stanowi błąd językowy) – może być przejawem nieporadności językowej. Błędem jest nieporadność językowa będąca oczywistym nieuzasadnionym naruszeniem obowiązującej normy językowej.
- Indywidualne upodobania językowe egzaminatora nie mogą wpływać na ocenę poprawności środków językowych w wypowiedzi zdającego. 
- Zadowalający zakres środków językowych oznacza, że zastosowane przez zdającego środki językowe, w szczególności leksykalne, umożliwiają omówienie zagadnienia oraz ułatwiają odbiór wypowiedzi; zdający stosuje wyrazy/wyrażenia synonimiczne, używa precyzyjnego słownictwa, w tym – terminologii. 
- Wąski zakres środków językowych oznacza, że leksyka i składnia są proste/ograniczone, utrudniają omówienie zagadnienia oraz odbiór wypowiedzi.
`;

function App() {
	const [zag1Kontekst, setZag1Kontekst] = useState(true);
	const [zag1PoziomArgumentacji, setZag1PoziomArgumentacji] = useState("bogata");
	const [zag1Rzeczowe, setZag1Rzeczowe] = useState("0");

	const [zag2PodanyUtwor, setZag2PodanyUtwor]= useState(true);
	const [zag2DrugiUtwor, setZag2DrugiUtwor] = useState(true);
	const [zag2PoziomArgumentacji, setZag2PoziomArgumentacji] = useState("bogata");
	const [zag2Rzeczowe, setZag2Rzeczowe] = useState("0");

	const [kompozycjaZag1, setKompozycjaZag1] = useState("spojna");
	const [kompozycjaZag2, setKompozycjaZag2] = useState("spojna");

	const [rozmowaNaTemat, setRozmowaNaTemat] = useState("naTemat");
	const [rozmowaZaburzenia, setRozmowaZaburzenia] = useState(false);

	const [jezykZakres, setJezykZakres] = useState("zadowalajacy");
	const [jezykCzesciowaPoprawnosc, setJezykCzesciowaPoprawnosc] = useState(false);

	const [wynik, setWynik] = useState(30);
	const [punktyZaZag1, setPunktyZaZag1] = useState(8);
	const [punktyZaZag2, setPunktyZaZag2] = useState(8);
	const [punktyZaKompozycje, setPunktyZaKompozycje] = useState(4);
	const [punktyZaRozmowe, setPunktyZaRozmowe] = useState(6);
	const [punktyZaJezyk, setPunktyZaJezyk] = useState(4);

	useEffect(() => {
		let nowyWynik = 0;

		if (zag1Rzeczowe !== "3+") {
			let punktyZaZag1DoDodania = 0;

			if (zag1Kontekst) {
				switch (zag1PoziomArgumentacji) {
					case "bogata":
						punktyZaZag1DoDodania = zag1Rzeczowe === "0" ? 8 : 7;
						break;
					case "zadowalajaca":
						punktyZaZag1DoDodania = zag1Rzeczowe === "0" ? 7 : 6;
						break;
					case "powierzchowna":
						punktyZaZag1DoDodania = zag1Rzeczowe === "0" ? 6 : 5;
						break;
				}
			} else {
				switch (zag1PoziomArgumentacji) {
					case "bogata":
						punktyZaZag1DoDodania = zag1Rzeczowe === "0" ? 4 : 3;
						break;
					case "zadowalajaca":
						punktyZaZag1DoDodania = zag1Rzeczowe === "0" ? 3 : 2;
						break;
					case "powierzchowna":
						punktyZaZag1DoDodania = zag1Rzeczowe === "0" ? 2 : 1;
						break;
				}
			}

			setPunktyZaZag1(punktyZaZag1DoDodania);
			nowyWynik += punktyZaZag1DoDodania;
		} else {
			setPunktyZaZag1(0);
		}

		if (zag2Rzeczowe !== "3+") {
			let punktyZaZag2DoDodania = 0;

			if (zag2PodanyUtwor && zag2DrugiUtwor) {
				switch (zag2PoziomArgumentacji) {
					case "bogata":
						punktyZaZag2DoDodania = zag2Rzeczowe === "0" ? 8 : 7;
						break;
					case "zadowalajaca":
						punktyZaZag2DoDodania = zag2Rzeczowe === "0" ? 7 : 6;
						break;
					case "powierzchowna":
						punktyZaZag2DoDodania = zag2Rzeczowe === "0" ? 6 : 5;
						break;
				}
			} else if (zag2PodanyUtwor || zag2DrugiUtwor) {
				switch (zag2PoziomArgumentacji) {
					case "bogata":
						punktyZaZag2DoDodania = zag2Rzeczowe === "0" ? 4 : 3;
						break;
					case "zadowalajaca":
						punktyZaZag2DoDodania = zag2Rzeczowe === "0" ? 3 : 2;
						break;
					case "powierzchowna":
						punktyZaZag2DoDodania = zag2Rzeczowe === "0" ? 2 : 1;
						break;
				}
			}

			setPunktyZaZag2(punktyZaZag2DoDodania);
			nowyWynik += punktyZaZag2DoDodania;
		} else {
			setPunktyZaZag2(0);
		}

		if (nowyWynik === 0) {
			setPunktyZaKompozycje(0);
			setPunktyZaJezyk(0);
			setWynik(0);
			return;
		}

		switch (kompozycjaZag1) {
			case "spojna":
				setPunktyZaKompozycje(2);
				nowyWynik += 2;
				break;
			case "czesciowoSpojna":
				setPunktyZaKompozycje(1);
				nowyWynik += 1;
				break;
			case "niespojna":
				setPunktyZaKompozycje(0);
				break;
		}

		switch (kompozycjaZag2) {
			case "spojna":
				setPunktyZaKompozycje(prevState => prevState + 2);
				nowyWynik += 2;
				break;
			case "czesciowoSpojna":
				setPunktyZaKompozycje(prevState => prevState + 1);
				nowyWynik += 1;
				break;
		}

		let punktyZaRomozweDoDodania = 0;

		switch (rozmowaNaTemat) {
			case "naTemat":
				punktyZaRomozweDoDodania = rozmowaZaburzenia ? 5 : 6;
				break;
			case "fragmenty":
				punktyZaRomozweDoDodania = rozmowaZaburzenia ? 3 : 4;
				break;
			case "wszystkieCzesciowo":
				punktyZaRomozweDoDodania = rozmowaZaburzenia ? 1 : 2;
				break;
			case "nieNaTemat":
				break;
		}

		setPunktyZaRozmowe(punktyZaRomozweDoDodania);
		nowyWynik += punktyZaRomozweDoDodania;

		let punktyZaJezykDoDodania = 0;

		switch (jezykZakres) {
			case "zadowalajacy":
				punktyZaJezykDoDodania = jezykCzesciowaPoprawnosc ? 3 : 4;
				break;
			case "waski":
				punktyZaJezykDoDodania = jezykCzesciowaPoprawnosc ? 1 : 2;
				break;
		}
		setPunktyZaJezyk(punktyZaJezykDoDodania);
		nowyWynik += punktyZaJezykDoDodania;

		setWynik(nowyWynik);
	}, [zag1Kontekst, zag1PoziomArgumentacji, zag1Rzeczowe, zag2PodanyUtwor, zag2DrugiUtwor, zag2PoziomArgumentacji, zag2Rzeczowe, kompozycjaZag1, kompozycjaZag2, rozmowaNaTemat, rozmowaZaburzenia, jezykZakres, jezykCzesciowaPoprawnosc]);

	return (
		<>
			<Paper elevation={3}>
				<div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
					<Calculate />
					<Typography variant="h5">&nbsp;Wybierz scenariusz</Typography>
				</div>

				<div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
					<div style={{padding: 10}}>
						<Typography variant="h6">Zagadnienie 1</Typography>

						<FormControlLabel control={<Checkbox checked={zag1Kontekst} onChange={(_, v) => setZag1Kontekst(v)} />} label="Kontekst przywołany przynajmniej częściowo funkcjonalnie" />

						<br/>

						<Typography variant="body1">Poziom argumentacji</Typography>
						<ToggleButtonGroup
							value={zag1PoziomArgumentacji}
							exclusive
							onChange={(_, v) => v ? setZag1PoziomArgumentacji(v) : null}
						>
							<ToggleButton value="bogata">
								Bogata
							</ToggleButton>
							<ToggleButton value="zadowalajaca">
								Zadowalająca
							</ToggleButton>
							<ToggleButton value="powierzchowna">
								Powierzchowna
							</ToggleButton>
						</ToggleButtonGroup>

						<br/>

						<Typography variant="body1">Ilość poważnych błędów rzeczowych</Typography>
						<ToggleButtonGroup
							value={zag1Rzeczowe}
							exclusive
							onChange={(_, v) => v ? setZag1Rzeczowe(v) : null}
						>
							<ToggleButton value="0">
								Brak
							</ToggleButton>
							<ToggleButton value="1-2">
								1 lub 2
							</ToggleButton>
							<ToggleButton value="3+">
								3 lub więcej
							</ToggleButton>
						</ToggleButtonGroup>

						<br/>

						<Typography variant="overline">
							Punktów za to zagadnienie: {punktyZaZag1}
						</Typography>
					</div>
					<div style={{padding: 10}}>
						<Typography variant="h6">Zagadnienie 2</Typography>

						<FormControlLabel control={<Checkbox checked={zag2PodanyUtwor} onChange={(_, v) => setZag2PodanyUtwor(v)} />} label="Odwołanie do podanego utworu" />
						<br/>
						<FormControlLabel control={<Checkbox checked={zag2DrugiUtwor} onChange={(_, v) => setZag2DrugiUtwor(v)} />} label="Odwołanie do innego utworu lub własnych doświadczeń" />

						<br/>

						<Typography variant="body1">Poziom argumentacji</Typography>
						<ToggleButtonGroup
							value={zag2PoziomArgumentacji}
							exclusive
							onChange={(_, v) => v ? setZag2PoziomArgumentacji(v) : null}
						>
							<ToggleButton value="bogata">
								Bogata
							</ToggleButton>
							<ToggleButton value="zadowalajaca">
								Zadowalająca
							</ToggleButton>
							<ToggleButton value="powierzchowna">
								Powierzchowna
							</ToggleButton>
						</ToggleButtonGroup>

						<br/>

						<Typography variant="body1">Ilość poważnych błędów rzeczowych</Typography>
						<ToggleButtonGroup
							value={zag2Rzeczowe}
							exclusive
							onChange={(_, v) => v ? setZag2Rzeczowe(v) : null}
						>
							<ToggleButton value="0">
								Brak
							</ToggleButton>
							<ToggleButton value="1-2">
								1 lub 2
							</ToggleButton>
							<ToggleButton value="3+">
								3 lub więcej
							</ToggleButton>
						</ToggleButtonGroup>

						<br/>

						<Typography variant="overline">
							Punktów za to zagadnienie: {punktyZaZag2}
						</Typography>
					</div>

					<div style={{padding: 10}}>
						<Typography variant="h6">Kompozycja</Typography>

						<Typography variant="body1">Zagadnienie 1.</Typography>
						<ToggleButtonGroup
							orientation="vertical"
							value={kompozycjaZag1}
							exclusive
							onChange={(_, v) => v ? setKompozycjaZag1(v) : null}
						>
							<ToggleButton value="spojna">
								Spójna
							</ToggleButton>
							<ToggleButton value="czesciowoSpojna">
								Częściowo spójna
							</ToggleButton>
							<ToggleButton value="niespojna">
								Niespójna
							</ToggleButton>
						</ToggleButtonGroup>

						<br/>

						<Typography variant="body1">Zagadnienie 2</Typography>
						<ToggleButtonGroup
							orientation="vertical"
							value={kompozycjaZag2}
							exclusive
							onChange={(_, v) => v ? setKompozycjaZag2(v) : null}
						>
							<ToggleButton value="spojna">
								Spójna
							</ToggleButton>
							<ToggleButton value="czesciowoSpojna">
								Częściowo spójna
							</ToggleButton>
							<ToggleButton value="niespojna">
								Niespójna
							</ToggleButton>
						</ToggleButtonGroup>
					</div>
				</div>

				<div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
					<div style={{padding: 10}}>
						<Typography variant="h6">Rozmowa</Typography>

						<Typography variant="body1">Jakość wypowiedzi</Typography>
						<ToggleButtonGroup
							value={rozmowaNaTemat}
							exclusive
							onChange={(_, v) => v ? setRozmowaNaTemat(v) : null}
						>
							<ToggleButton value="naTemat">
								Na temat
							</ToggleButton>
							<ToggleButton value="fragmenty">
								Fragmenty na temat
							</ToggleButton>
							<ToggleButton value="wszystkieCzesciowo">
								Całość częściowo na temat
							</ToggleButton>
							<ToggleButton value="nieNaTemat">
								Nie na temat
							</ToggleButton>
						</ToggleButtonGroup>

						<br/>

						<FormControlLabel control={<Checkbox checked={rozmowaZaburzenia} onChange={(_, v) => setRozmowaZaburzenia(v)} />} label="Zaburzenia w stopniu uszczegółowienia wypowiedzi względem postawionych pytań" />
					</div>

					<div style={{padding: 10}}>
						<Typography variant="h6">Zakres i poprawność śr. językowych</Typography>

						<Typography variant="body1">Zakres środków językowych</Typography>
						<ToggleButtonGroup
							value={jezykZakres}
							exclusive
							onChange={(_, v) => v ? setJezykZakres(v) : null}
						>
							<ToggleButton value="zadowalajacy">
								Zadowalający
							</ToggleButton>
							<ToggleButton value="waski">
								Wąski
							</ToggleButton>
							<ToggleButton value="brak">
								Niekomunikatywa/brak poprawności językowej
							</ToggleButton>
						</ToggleButtonGroup>

						<br/>

						<FormControlLabel control={<Checkbox checked={jezykCzesciowaPoprawnosc} onChange={(_, v) => setJezykCzesciowaPoprawnosc(v)} />} label="Tylko częściowo zachowano właściwą dla języka mówionego poprawność językową" />
					</div>
				</div>
			</Paper>
			<Paper elevation={3}>
				<div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
					<Speed />
					<Typography variant="h5">&nbsp;Wynik</Typography>
				</div>
				<div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
					<Typography variant="h1">{wynik}/30</Typography>
					<Typography variant="body1" align="center">
						Aspekt merytoryczny wypowiedzi monologowych:<br/><b>{punktyZaZag1 + punktyZaZag2}/16</b>
					</Typography>
					<Typography variant="body1" align="center">
						Kompozycja wypowiedzi monologowych:<br/><b>{punktyZaKompozycje}/4</b>
					</Typography>
					<Typography variant="body1" align="center">
						Aspekt merytoryczny wypowiedzi podczas rozmowy:<br/><b>{punktyZaRozmowe}/6</b>
					</Typography>
					<Typography variant="body1" align="center">
						Zakres i poprawność środków językowych w wypowiedziach zdającego:<br/><b>{punktyZaJezyk}/4</b>
					</Typography>
					<Typography variant="h1">{Math.floor(wynik / 30 * 100)}%</Typography>
				</div>
			</Paper>
			<Paper elevation={3}>
				<div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
					<AutoStories />
					<Typography variant="h5">&nbsp;Słownik pojęć</Typography>
				</div>
				<div style={{overflow: "auto", flex: "1 1 auto"}}>
					<ReactMarkdown children={dictionaryMarkdown} />
				</div>
			</Paper>
		</>
	);
}

export default App;
